<template>
  <NavBar />
  <router-view />
  <ScrollTopComponent>
    <a class="btn btn-light"> </a>
  </ScrollTopComponent>
  <Footer />
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer";
import ScrollTopComponent from "./components/ScrollTopComponent";
export default {
  components: {
    NavBar,
    Footer,
    ScrollTopComponent
  }
};
</script>

<style>
.btn {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.55);
  padding-top: 27px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}
</style>
