import { createRouter, createWebHistory } from "vue-router";
import NotFoundPage from "../components/NotFoundPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      default: () => import("../views/Home.vue")
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () =>
        import(/* webpackChunkName: "about" */ "../views/About.vue")
    }

    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/products",
    name: "Products",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "products" */ "../views/Products/Products.vue"
        )
    }
  },
  {
    path: "/products/highbay",
    name: "HighBay",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "highbay" */ "../views/Products/HighBay.vue"
        )
    }
  },
  {
    path: "/products/interface",
    name: "Interface",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "interface" */ "../views/Products/Interface.vue"
        )
    }
  },
  {
    path: "/products/street",
    name: "Street",
    components: {
      default: () =>
        import(/* webpackChunkName: "street" */ "../views/Products/Street.vue")
    }
  },
  {
    path: "/products/twilight",
    name: "Twilight",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "street" */ "../views/Products/Twilight.vue"
        )
    }
  },
  // {
  //   path: "/products/:family",
  //   name: "Family",
  //   components: {
  //     default: () =>
  //       import(/* webpackChunkName: "street" */ `../views/Products/Family.vue`),
  //   },
  // },
  {
    path: "/products/:code",
    name: "Code",
    components: {
      default: () =>
        import(/* webpackChunkName: "street" */ "../views/Products/Code.vue")
    }
  },

  {
    path: "/not-found",
    component: NotFoundPage
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes

  // scrollBehavior() {
  //   // document.getElementById("app").scrollIntoView();
  //   // window.scroll(0,0)
  // },
});

export default router;
