<template>
  <div class="notfound">
    <p></p>
    <p>error - 404</p>
    <p>NotFoundPage</p>
    <p>ㄟ( ▔, ▔ )ㄏ</p>
  </div>
</template>

<style>
.notfound {
  height: 80vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: clamp(1em, 10vw, 6em);
}
</style>
