<template>
  <div id="nav">
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-dark"
      :class="{ 'rounded-test': true }"
    >
      <div class="container fs-5">
        <a class="navbar-brand nav-link" href="/"
          ><span class="fw-bold">الصباغ® </span> للصناعات الكهربائية
          <!-- <img
            src="../assets/images/evora-logo.svg"
            alt="evora logo"
            class="img-fluid py-2"
        /> -->
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item active">
              <router-link class="nav-link" to="/">الصفحة الرئيسية</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/products"
                >منتجاتنا</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about">حول الشركة</router-link>
            </li>
            <li class="nav-item mx-5 my-auto">
              <a href="tel:+20 102 696 4584" class="btn btn-light">اتصل بنا</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- End Navbar -->
  </div>
</template>
