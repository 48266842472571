import { createStore } from "vuex";
import json from "./productsData.json";
import imgPath from "./imgPath.json";

export default createStore({
  state: {
    productsData: json.productsData,
    imagesPath: imgPath,
  },
  getters: {
    getData: (state) => state.productsData,
  },
});
