<template>
  <!--     Start Footer    -->

  <div class="footer">
    <div class="container">
      <div class="row py-5">
        <div class="col-sm-12 col-lg-4">
          <div class="site-info">
            <h2 class="footer-oxygen-logo">شركة الصباغ للصناعات الكهربائية</h2>
            <p>
              مقر الشركة الرئيسي: مصر - العاشر من رمضان - المنطقة الصناعية
              الثالثة
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-lg-3">
          <div class="helpful-links">
            <h2>الروابط</h2>
            <ul class="list-unstyled">
              <li>
                <router-link to="/">الصفحة الرئيسية</router-link>
              </li>
              <li>
                <router-link to="/products">منتجاتنا</router-link>
              </li>
              <li>
                <router-link to="/about">حول الشركة</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-lg-3">
          <div class="contact">
            <h2>للتواصل معنا</h2>
            <ul class="list-unstyled">
              <li></li>
              <li>
                <i class="fa fa-phone"></i> &nbsp; :
                <a href="tel:+20 102 696 4584" dir="ltr">+20 102 696 4584</a>
              </li>
              <li>
                <i class="fas fa-at"></i> &nbsp; :
                <a href="mailto:m.sabagh999@gmail.com" dir="ltr"
                  >m.sabagh999@gmail.com</a
                >
              </li>
              <li>
                <i class="fas fa-at"></i> &nbsp; :
                <a href="mailto:moonlight.sabagh@gmail.com" dir="ltr"
                  >moonlight.sabagh@gmail.com</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-lg-2">
          <div class="fs-4">
            <img
              src="../assets/images/QR-code.jpeg"
              class="img-thumbnail"
              width="150"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--     End Footer    -->

  <!--     Start Copyright    -->

  <div class="copyright" dir="ltr">
    <div class="container py-2">
      <div class="row">
        <div class="col-sm-12 text-sm-center col-md-4">
          <ul
            class="
              list-unstyled
              d-flex
              justify-content-around
              align-items-center
              fs-5
            "
          >
            <li>
              <a href="https://www.facebook.com/100222895445465" target="blank"
                ><i class="i-face fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC0uNSCiMI6BNI0ZtQOrsr6A"
                target="blank"
                ><i class="fab fa-youtube"></i
              ></a>
            </li>
            <li>
              <a href="https://twitter.com/Sabagh1997" target="blank"
                ><i class="fab fa-twitter"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/moon.light.solution/"
                target="blank"
                ><i class="i-inst fab fa-instagram"></i
              ></a>
            </li>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=201026964584&text=%D8%A7%D9%84%D8%B3%D9%84%D8%A7%D9%85%20%D8%B9%D9%84%D9%8A%D9%83%D9%85"
                target="blank"
                ><i class="fab fa-whatsapp"></i
              ></a>
            </li>
            <li>
              <a href="https://t.me/sabbaghlight" target="blank"
                ><i class="fab fa-telegram-plane"></i
              ></a>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 text-sm-end text-center col-md-8 text-uppercase">
          <h5 class="copy-heading">
            Made with <i class="fa fa-heart fa-fw"></i> &
            <i class="fa fa-coffee fa-fw"></i> BY
            <a href="https://oxygendevs.netlify.app/" target="blank">
              <img
                src="@/assets/images/ogygen-devs-dark-logo.svg"
                alt=""
                class="oxygendevs-logo"
              />
            </a>
            &copy;
          </h5>
        </div>
      </div>
    </div>
  </div>
  <!--     End Copyright    -->
</template>

<style>
.copyright {
  background: #f2f2f2;
  position: relative;
  z-index: 9999;
}
</style>
