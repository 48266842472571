<template>
  <a @click="scrollTop" v-show="visible" class="bottom-right">
    <i class="fas fa-arrow-up"></i>
  </a>
</template>

<script>
export default {
  data() {
    return { visible: false };
  },
  methods: {
    scrollTop() {
      window.scroll(0, 0);
    },
    scrollListener() {
      this.visible = window.scrollY > 150;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrollListener);
  }
};
</script>

<style scoped>
.bottom-right {
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  background-color: #f39200;
  padding: 0.5em 0.8em;
  font-size: 1.6em;
  font-weight: bold;
  border-radius: 50%;
  box-shadow: 1px 1px 10px rgba(34, 34, 34, 0.5);
  transition: all 0.2s ease-in-out;
  z-index: 99999999;
}

@media screen and (max-width: 600px) {
  .bottom-right {
    padding: 0.4em 0.7em;
    bottom: 25px;
    right: 25px;
    z-index: 999 !important;
  }
}
.bottom-right:hover {
  box-shadow: 1px 1px 10px rgba(34, 34, 34, 0.7);
  transform: scale(1.05);
  color: inherit !important;
}
</style>
