import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
// import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/style/style.css";

createApp(App)
  .use(store)
  .use(router)
  .mount("#app");
